@import '../../styles/colors.scss';
.page {
    display: grid;
    grid-template-rows: repeat(1fr);
    gap: 120px;
    padding: 80px 16px;
    max-width: 1168px;
    margin: 0 auto;
    @media only screen and (max-width: 480px) {
        padding-top: 24px;
        gap: 80px;
      }
  
    .intro {
      display: grid;
      grid-template-rows: repeat(1fr);
      gap: 40px;
      max-width: 1168px;
      justify-content: start;
    }

    .img {
        width: 100%;
        min-width: 300px;
        border-radius: 2vmin;
        @media only screen and (max-width: 600px) {
            border-radius: 4vmin;
        }
      }

      .text-paragraph {
        display: grid;
        grid-template-rows: repeat(1fr);
        gap: 12px;
        max-width: 1168px;
        }
    
    .youtube-video {
        aspect-ratio: 16 / 9;
        width: 100%;
        border-radius: 2vmin;
        @media only screen and (max-width: 600px) {
            border-radius: 4vmin;
        }
    }

    .key-features {
        display: grid;
        grid-template-rows: repeat(1fr);
        gap: 40px;
        max-width: 1168px;
        
    .features-grid-odd {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }
    }

    .features-grid-even {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 600px) {
            flex-direction: column-reverse;
        }
    }
    
    .features-grid-text-odd {
        display: grid;
        grid-template-rows: repeat(1fr);
        gap: 24px;
        max-width: 400px;
        margin-right: 40px;
        @media only screen and (max-width: 600px) {
            margin-right: 0;
            }
        }
        
    .features-grid-text-even {
        display: grid;
        grid-template-rows: repeat(1fr);
        gap: 24px;
        max-width: 400px;
        margin-left: 40px;
        @media only screen and (max-width: 600px) {
            margin-left: 0;
            }
        }
    }

    .link {
        color: var(--brand-foreground) !important;
        font-weight: 600;
    }


    .carousel-control-next-icon,
    .carousel-control-prev-icon {
    filter: invert(1);
    }

    .column-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 40px;

        .column {
            display: grid;
            grid-template-rows: repeat(1fr);
            gap: 40px;
            max-width: 360px;

            .column-heading {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 12px;
            }
        }
    }

    .even-grid {
        display: grid;
        grid-template-rows: repeat(1fr);
        gap: 40px;
    }

        ul {
            list-style-position: outside;
            padding-left: 16px;
        }

        li {
            line-height: 28px;
        }

        .see-more {
            max-width: 800px;
            text-align: center;
            margin: 0 auto;
        }

        .next {
            display: block;
            padding-top: 80px;
            border-top: 1px solid var(--border);
            @media only screen and (max-width: 600px) {
                padding-top: 40px;
            }

            .next-grid {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                gap: 40px;
        
                .explore {
                    display: flex;
                    text-decoration: none;
                    align-items: center;
                    margin: 12px 0;
                    color: var(--brand-foreground) !important;
                    fill: var(--brand-foreground) !important;
                    border-radius: 8px;
                    @media only screen and (max-width: 600px) {
                    margin-left: 0;
                    margin-top: 8px;
                    }
        
                    &:hover {
                        text-decoration: underline;
                        text-decoration-color: var(--brand-foreground);
                    }
                }
            }

        }
}