@import '../../styles/colors.scss';
.work {
  width: 100%;
  padding: 0 16px;

  .intro {
    display: block;
    max-width: 1168px;
    justify-content: start;
    margin: 0 auto;
    padding-top: 80px;
    @media only screen and (max-width: 480px) {
      padding-top: 24px;
    }

    .projects-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 80px;
      border-top: 1px solid var(--border);
      border-spacing: 80px;
      justify-content: start;
    }

    .text {
      display: grid;
      max-width: 688px;
      text-align-last: start;
      gap: 8px 0;
      margin-bottom: 80px;

      .caption {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 32px;
        @media only screen and (max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;
          margin: 8px 0;
        }

        .link {
          display: flex;
          text-decoration: none;
          align-items: center;
          margin-left: 16px;
          color: var(--foreground4) !important;
          fill: var(--foreground4) !important;
          border-radius: 8px;
          @media only screen and (max-width: 600px) {
            margin-left: 0;
            margin-top: 32px;
          }

          &:hover {
            color: var(--brand-foreground) !important;
            fill: var(--brand-foreground) !important;
            text-decoration: underline;
            text-decoration-color: var(--foreground4);
            transition: 0.2s ease-in-out;
          }
        }

      }
    }
  }

  .project-list {
    max-width: 1008px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    @media only screen and (max-width: 600px) {
      margin-top: 40px;
    }

    .row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 0 40px;
      margin: 0;
    }

    .project {
      padding-bottom: 120px;
      cursor: pointer;
      @media only screen and (max-width: 600px) {
        padding-bottom: 80px;
    }

      .item {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 40px;
        padding-bottom: 24px;
        @media only screen and (max-width: 600px) {
          gap: 16px;
        }
      }

      .img {
        width: 100%;
        min-width: 300px;
        border-radius: 2vmin;
      }
    }


  }
}

.projects-title {
  display: flex;
  max-width: 1168px;
  justify-content: start;
  margin: 0 auto;
  padding: 80px 0;
  @media only screen and (max-width: 600px) {
    padding: 40px 0;
  }

}

.image-mask {
  clip-path: circle(48px);
}