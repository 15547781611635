@import '../../styles/colors.scss';



nav {
    height: auto;
    display: flex;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    max-width: 1168px;
    width: 100%;
    padding: 0 16px;
    margin:  12px auto 0 auto;
    z-index: 1;
    @media (max-width: 480px) {
      padding-top: 16px;
      justify-content: flex-start;
      flex-direction: column;
      border-bottom: 1px solid var(--border);
    }

    .active {
      border-bottom: 4px solid var(--brand-foreground);
      border-radius: 0;
      text-decoration: 0;
      color: var(--foreground2);
    }
  }

nav .title {
  text-decoration: none;
}

nav ul {
  display: flex;
  flex-direction: row;
  height: 48px;
  
}

nav ul li {
  list-style: none;
  background-color: white;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: var(--foreground2);
  margin: 0 8px;
  padding: 24px 16px 12px 16px;
  border-radius: 8px;
  @media (max-width: 480px) {
  }
}

nav ul li a:not(.active):hover {
  background-color: #F5F5F5;
  color: var(--brand-foreground);
  transition: 0.2s ease-in-out;
  text-decoration: none;
}

.button-stack {
  display: flex;
  height: 48px;
  gap: 8px;
}

.site-title {
  text-decoration: none;
}