:root {
    --font-primary: sans-serif;
    --font-secondary: serif;
}

h1, h3, p {
    color: var(--foreground1);
}

h2, h4, h5, h6, b {
    color: var(--foreground2);
}

a {
    color: var(--brand-foreground);
}

h1 {
    font-size: 40px;
    line-height: 49px;
    font-weight: 700;
    font-style: normal;
    margin:0;
    @media only screen and (max-width: 600px) {
        font-size: 32px;
        line-height: 40px;
    }
}

h2 {
    font-size: 40px;
    line-height: 56px;
    font-weight: 500;
    font-style: normal;
    margin:0;
    @media only screen and (max-width: 600px) {
        font-size: 28px;
        line-height: 40px;
    }
}

h3 {
    font-size: 32px;
    line-height: 39px;
    font-weight: 600;
    font-style: normal;
    margin:0;
    @media only screen and (max-width: 600px) {
        font-size: 24px;
        line-height: 32px;
    }
}

h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    font-style: italic;
    margin:0;
}

h5 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-style: normal;
    display: flex;
    margin:0;
    text-align: left;
}

h6 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    font-style: normal;
    margin:0;
}

b {
    font-size: 16px;
    line-height: 40px;
    font-weight: 700;
    font-style: normal;
    margin:0;
}

a {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-style: normal;
    display: inline-block;
    margin:0;
    text-align: left;
}
p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 0 !important;
}