:root {
    --foreground1: #342E37;
    --foreground2: #5C5162;
    --foreground3: #FFFFFF;
    --foreground4: #978D9C;
    --background: #FFFFFF;
    --border: #E0E0E0;
    --brand-foreground: #4C0F9A;
    --brand-background: #4C0F9A;
}
