.dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 16px;
    margin: 0 auto;
    height: calc(100vh - 244px);
    max-width: 1008px;

    .dialog-content {
        gap: 24px;
    }
  }