@import '../../styles/colors.scss';
.about {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: auto 1fr;
    overflow-y: auto;
    padding: 80px 16px;
    margin: 0 auto;
    height: calc(100vh - 244px);
    max-width: 1008px;
    gap: 0 40px;
    @media only screen and (max-width: 780px) {
        height: 100%;
      }  
    @media only screen and (max-width: 480px) {
        margin: 0;
        padding: 24px 16px;
      }  

    .image-mask-rect {
        width: 100%; 
        height: 600px; 
        overflow: hidden; 
        flex-direction: column;
        border-radius: 2vw; 
        align-items: center; 
        display: inline-flex;
        @media only screen and (max-width: 480px) {
            border-radius: 6vw;
            height: 400px; 
          }
          
        .image {
            height: 808px;
            translate: 0px -150px;
            @media only screen and (max-width: 480px) {
                height: 600px;
              }  
        }
    
    }

    .text-paragraph {
        display: grid;
        grid-template-rows: repeat(1fr);
        gap: 12px;
        max-width: 1168px;
    }

    .heading {
        padding-bottom: 16px;
        @media only screen and (max-width: 672px) {
            margin-top: 40px;
          }  
    }

    p {
        margin-bottom: 28px; /* between paragraphs */
        }

    .paragraph {
        margin-bottom: 12px;
    }

    ul {
        list-style-position: outside;
        padding-left: 16px;

        li {
            line-height: 28px;
        }
    }

    .footer {
        position: absolute;
        @media only screen and (max-width: 780px) {
            position: relative;
          }  
    }

    .link {
        color: var(--brand-foreground) !important;
        font-weight: 600;
    }
}