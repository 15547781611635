@import '../../styles/colors.scss';
.icon-button {
    width: fit-content;
    display: flex;
    padding-left: 8px;
    padding-right: 12px;
    height: 48px;
    background-color: var(--background);
    align-content: center;
    line-height: 48px;
    margin: 8px, 0px;
    border-radius: 8px;
    font-weight: 700;
    align-items: center;
    color: var(--foreground2);
    cursor: pointer;
    &:hover {
        background-color: #F5F5F5;
      }
}
.email-icon {
    background-image: url('../../assets/icons/Email.svg') ;
}

.linkedin-icon {
    background-image: url('../../assets/icons/LinkedIn.svg') ;
}
.dribbble-icon {
    background-image: url('../../assets/icons/Dribbble.svg') ;
}

.button {
    width: fit-content;
    display: flex;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    height: 48px;
    border: 2px solid var(--brand-foreground);
    background-color: var(--background);
    align-content: center;
    line-height: 48px;
    margin: 8px, 0px;
    border-radius: 8px;
    font-weight: 700;
    color: var(--brand-foreground);
    cursor: pointer;
    &:hover {
        background-color: var(--brand-background);
        color: var(--foreground3);
      }
}

.tab-button {
    width: fit-content;
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    height: 48px;
    background-color: var(--background);
    align-content: center;
    line-height: 48px;
    margin: 8px, 0px;
    border-radius: 8px;
    font-weight: 700;
    color: var(--foreground1);
    cursor: pointer;
    &:hover {
        background-color: #F5F5F5;
      }
    &:active {
        border-bottom: 2px solid var(--brand-foreground);
      }
}