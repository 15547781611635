.footer {
width: 100%;
color: #5C5162;
background-color: #FFFFFF;
display: block;
justify-content: center;
text-align: center;
align-items: center;
padding: 40px 16px;

.buttons {
display: flex;
flex-direction: row;
place-items: center;
justify-content: center;
padding-bottom: 16px;
@media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}
}